import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import React from 'react';
import { App, Login } from './views';

export default function AppRouter (props) {
    const navigate = useNavigate();

    return (
        <Routes>
            <Route path="/" element={<App style={{"--cursor-you-img": `url(${process.env.REACT_APP_URL}/you.svg), auto`}} />} />
            <Route path="/login" element={<Login navigate={navigate}/> } />
            <Route path="*" element={<p>404 -&gt; TBD </p>} />
        </Routes>
    )
}